import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as config from "./config"
import Stats from 'stats.js'
import { VRButton } from 'three/addons/webxr/VRButton.js';

// Debug shows gui for setting 3d-walk points coords
const debugMode = true;

/** 
 * Debug
 */

let gui = null;
if (debugMode) gui = new dat.GUI({ width: 400 });

const stats = new Stats()
stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
stats.dom.style.position = 'relative';
document.body.appendChild(stats.dom)

/**
 * Base
 */

let loaded = false;
let dragging = false;
let currentRoom = null;
let savedRoom = null;
let showRoomInfo = false;
let showBuildingModel = true;
let showWalk3d = false;
let currentCarousel = 0;
let loadedImages = 0;
const defaultCameraRotation = 225;
let cameraRotation = defaultCameraRotation;

// Loading
const wrapper = document.getElementById("3d-wrapper");
const canvas = document.querySelector("canvas.webgl");
const canvasWrapper = document.getElementById("canvas-wrapper");
const prompt = document.getElementById("hover-prompt");
const loadingProgress = document.getElementById("loading-progress");
const closeRoomInfo = document.getElementById("close-room-info");
const hideRoomInfo = document.getElementById("hide-menu-button");
const buttonPlan3d = document.getElementById("plan-3d");
const buttonWalk3d = document.getElementById("walk-3d");
const buttonBuilding3d = document.getElementById("building-3d");
const carousel = document.getElementById("carousel-content");
const buttonNextCarousel = document.getElementById("carousel-prev");
const buttonPrevCarousel = document.getElementById("carousel-next");
const dummy = document.getElementById('dummy-images');
const walk3dPoints = document.getElementById('walk-3d-points');
const walk3dMinimap = document.getElementById('walk-3d-minimap');
const walk3dCameras = document.getElementsByClassName('walk-3d-camera');


const sizes = {
	default_width: canvasWrapper.clientWidth,
	default_height: canvasWrapper.clientHeight,
	width: canvasWrapper.clientWidth,
	height: canvasWrapper.clientHeight,
};

const loadingManager = new THREE.LoadingManager(
	() => { // Loaded
		wrapper.classList.remove("loading");
		wrapper.classList.add("loaded");
		dragging = false;
		loaded = true;
	},
	(_, itemsLoaded, itemsTotal) => { // Progress
		const progress = `${(itemsLoaded / itemsTotal) * 100}%`;
		loadingProgress.style.width = progress;
	}
);

const activateButton = (button) => {
	buttonPlan3d.disabled = false;
	buttonWalk3d.disabled = false;
	buttonBuilding3d.disabled = false;
	hideRoomInfo.disabled = false;

	buttonPlan3d.classList.remove("active");
	buttonWalk3d.classList.remove("active");
	buttonBuilding3d.classList.remove("active");

	button.classList.add("active");
};

const getRoomHoverInfo = (roomId) => {
	// TODO Fetch room info by roomId
	const id = roomId.replace(/\D/g,'');
	return {
		id,
		name: 'Apartament ' + id,
		image: './images/rooms/apartment_top_transparent.png',
		description: 'Powierzchnia: 50m2, 1 sypialnia, 1 salon, 1 łazienka, aneks kuchenny, korytarz, balkon',
		plan_3d: [
			'./images/rooms/orbit/0001.jpg',
			'./images/rooms/orbit/0002.jpg',
			'./images/rooms/orbit/0003.jpg',
			'./images/rooms/orbit/0004.jpg',
			'./images/rooms/orbit/0005.jpg',
			'./images/rooms/orbit/0006.jpg',
			'./images/rooms/orbit/0007.jpg',
			'./images/rooms/orbit/0008.jpg',
			'./images/rooms/orbit/0009.jpg',
			'./images/rooms/orbit/0010.jpg',
			'./images/rooms/orbit/0011.jpg',
			'./images/rooms/orbit/0012.jpg',
			'./images/rooms/orbit/0013.jpg',
			'./images/rooms/orbit/0014.jpg',
			'./images/rooms/orbit/0015.jpg',
			'./images/rooms/orbit/0016.jpg',
			'./images/rooms/orbit/0017.jpg',
			'./images/rooms/orbit/0018.jpg',
			'./images/rooms/orbit/0019.jpg',
			'./images/rooms/orbit/0020.jpg',
			'./images/rooms/orbit/0021.jpg',
			'./images/rooms/orbit/0022.jpg',
			'./images/rooms/orbit/0023.jpg',
			'./images/rooms/orbit/0024.jpg',
			'./images/rooms/orbit/0025.jpg',
			'./images/rooms/orbit/0026.jpg',
			'./images/rooms/orbit/0027.jpg',
			'./images/rooms/orbit/0028.jpg',
			'./images/rooms/orbit/0029.jpg',
			'./images/rooms/orbit/0030.jpg',
			'./images/rooms/orbit/0031.jpg',
			'./images/rooms/orbit/0032.jpg',
			'./images/rooms/orbit/0033.jpg',
			'./images/rooms/orbit/0034.jpg',
			'./images/rooms/orbit/0035.jpg',
			'./images/rooms/orbit/0036.jpg',
			'./images/rooms/orbit/0037.jpg',
			'./images/rooms/orbit/0038.jpg',
			'./images/rooms/orbit/0039.jpg',
			'./images/rooms/orbit/0040.jpg',
			'./images/rooms/orbit/0041.jpg',
			'./images/rooms/orbit/0042.jpg',
			'./images/rooms/orbit/0043.jpg',
			'./images/rooms/orbit/0044.jpg',
			'./images/rooms/orbit/0045.jpg',
			'./images/rooms/orbit/0046.jpg',
			'./images/rooms/orbit/0047.jpg',
			'./images/rooms/orbit/0048.jpg',
			'./images/rooms/orbit/0049.jpg',
			'./images/rooms/orbit/0050.jpg',
			'./images/rooms/orbit/0051.jpg',
			'./images/rooms/orbit/0052.jpg',
			'./images/rooms/orbit/0053.jpg',
			'./images/rooms/orbit/0054.jpg',
			'./images/rooms/orbit/0055.jpg',
			'./images/rooms/orbit/0056.jpg',
			'./images/rooms/orbit/0057.jpg',
			'./images/rooms/orbit/0058.jpg',
			'./images/rooms/orbit/0059.jpg',
			'./images/rooms/orbit/0060.jpg',
			'./images/rooms/orbit/0061.jpg',
			'./images/rooms/orbit/0062.jpg',
			'./images/rooms/orbit/0063.jpg',
			'./images/rooms/orbit/0064.jpg',
			'./images/rooms/orbit/0065.jpg',
			'./images/rooms/orbit/0066.jpg',
			'./images/rooms/orbit/0067.jpg',
			'./images/rooms/orbit/0068.jpg',
			'./images/rooms/orbit/0069.jpg',
			'./images/rooms/orbit/0070.jpg',
			'./images/rooms/orbit/0071.jpg',
			'./images/rooms/orbit/0072.jpg',
			'./images/rooms/orbit/0073.jpg',
			'./images/rooms/orbit/0074.jpg',
			'./images/rooms/orbit/0075.jpg',
			'./images/rooms/orbit/0076.jpg',
			'./images/rooms/orbit/0077.jpg',
			'./images/rooms/orbit/0078.jpg',
			'./images/rooms/orbit/0079.jpg',
			'./images/rooms/orbit/0080.jpg',
			'./images/rooms/orbit/0081.jpg',
			'./images/rooms/orbit/0082.jpg',
			'./images/rooms/orbit/0083.jpg',
			'./images/rooms/orbit/0084.jpg',
			'./images/rooms/orbit/0085.jpg',
			'./images/rooms/orbit/0086.jpg',
			'./images/rooms/orbit/0087.jpg',
			'./images/rooms/orbit/0088.jpg',
			'./images/rooms/orbit/0089.jpg',
			'./images/rooms/orbit/0090.jpg',
			'./images/rooms/orbit/0091.jpg',
			'./images/rooms/orbit/0092.jpg',
			'./images/rooms/orbit/0093.jpg',
			'./images/rooms/orbit/0094.jpg',
			'./images/rooms/orbit/0095.jpg',
			'./images/rooms/orbit/0096.jpg',
			'./images/rooms/orbit/0097.jpg',
			'./images/rooms/orbit/0098.jpg',
			'./images/rooms/orbit/0099.jpg',
			'./images/rooms/orbit/0100.jpg',
			'./images/rooms/orbit/0101.jpg',
			'./images/rooms/orbit/0102.jpg',
			'./images/rooms/orbit/0103.jpg',
			'./images/rooms/orbit/0104.jpg',
			'./images/rooms/orbit/0105.jpg',
			'./images/rooms/orbit/0106.jpg',
			'./images/rooms/orbit/0107.jpg',
			'./images/rooms/orbit/0108.jpg',
		],
		walk_3d: [
			{
				name: 'Salon',
				hri: './images/rooms/walk/salon/',
				hri_type: 'jpg',
				position: {
					x: 0.39,
					y: 0.67,
				},
				points: [
					{
						name: 'Kuchnia',
						position: new THREE.Vector3(0, 0, -10),
						icon: './images/apple.png',
					},
					{
						name: 'Korytarz',
						position: new THREE.Vector3(-22, 0, -43),
						icon: './images/door.png',
					},
					{
						name: 'Balkon',
						position: new THREE.Vector3(0, 0, 17),
						icon: './images/tree.png',
					}
				],
			},
			{
				name: 'Korytarz',
				hri: './images/rooms/walk/korytarz/',
				hri_type: 'jpg',
				position: {
					x: 0.76,
					y: 0.42,
				},
				points: [
					{
						name: 'Salon',
						position: new THREE.Vector3(25, 0, 40),
						icon: './images/tv.png',
					},
					{
						name: 'Kuchnia',
						position: new THREE.Vector3(43, 0, 3),
						icon: './images/apple.png',
					},
					{
						name: 'Łazienka',
						position: new THREE.Vector3(-12, 0, 50),
						icon: './images/shower.png',
					},
					{
						name: 'Sypialnia',
						position: new THREE.Vector3(4, 0, 100),
						icon: './images/bed.png',
					},
				],
			},
			{
				name: 'Sypialnia',
				hri: './images/rooms/walk/sypialnia/',
				hri_type: 'jpg',
				position: {
					x: 0.3,
					y: 0.34,
				},
				points: [
					{
						name: 'Korytarz',
						position: new THREE.Vector3(20, 0, -42),
						icon: './images/door.png',
					},
				],
			},
			{
				name: 'Łazienka',
				hri: './images/rooms/walk/lazienka/',
				hri_type: 'jpg',
				position: {
					x: 0.54,
					y: 0.25,
				},
				points: [
					{
						name: 'Korytarz',
						position: new THREE.Vector3(100, 0, 0),
						icon: './images/door.png',
					},
				],
			},
			{
				name: 'Kuchnia',
				hri: './images/rooms/walk/kuchnia/',
				hri_type: 'jpg',
				position: {
					x: 0.65,
					y: 0.69,
				},
				points: [
					{
						name: 'Korytarz',
						position: new THREE.Vector3(-88, 0, 9),
						icon: './images/door.png',
					},
					{
						name: 'Łazienka',
						position: new THREE.Vector3(-100, 0, 82),
						icon: './images/shower.png',
					},
					{
						name: 'Salon',
						position: new THREE.Vector3(0, 0, 100),
						icon: './images/tv.png',
					},
				],
			},
			{
				name: 'Balkon',
				hri: './images/rooms/walk/balkon/',
				hri_type: 'jpg',
				position: {
					x: 0.15,
					y: 0.69,
				},
				points: [
					{
						name: 'Salon',
						position: new THREE.Vector3(-4, 0, 37),
						icon: './images/tv.png',
					},
				],
			},
		],
	};
};

const fillRoomInfo = () => {
	if (!showRoomInfo) {
		window.dispatchEvent(new Event('resize'));
		wrapper.classList.add("show-room-info");
	}
	wrapper.classList.add("show-room-3d");
	showRoomInfo = true;
	activateButton(buttonPlan3d);
	const roomName = document.getElementById("room-name");
	const roomDescription = document.getElementById("room-description");
	const roomImage = document.getElementById("room-image");
	if (currentRoom && (!savedRoom || currentRoom.id !== savedRoom.id)) {
		savedRoom = currentRoom;
		currentCarousel = 0;
		loadedImages = 0;
		
		roomName.innerHTML = savedRoom.name;
		roomDescription.innerHTML = savedRoom.description ?? 'Brak szczegółowych informacji';
		if (roomImage.src !== (savedRoom.image ?? 'https://via.placeholder.com/300')) {
			roomImage.src = savedRoom.image ?? 'https://via.placeholder.com/300';
		}
		if (walk3dMinimap.src !== (savedRoom.image ?? 'https://via.placeholder.com/300')) {
			walk3dMinimap.src = savedRoom.image ?? 'https://via.placeholder.com/300';
		}
		roomImage.alt = 'Rzut pokoju' + savedRoom.name;
		carousel.classList.add('loading');
		carousel.innerHTML = `<div class="loading-animation"></div>`;
		dummy.innerHTML = '';
		savedRoom.plan_3d.forEach((image, index) => {
			if (index === currentCarousel) {
				const img = document.createElement('img');
				img.src = image;
				img.alt = 'Rzut 3D ' + savedRoom.name;
				img.id = 'current-carousel-image';
				img.onload = () => {
					loadedImages++;
					if (loadedImages === savedRoom.plan_3d.length) {
						carousel.classList.remove('loading');
					}
				};
				carousel.appendChild(img);
				// TODO Check why loading is not visible
				// TODO Add loading animation
			}
			const img = document.createElement('img');
			img.src = image;
			img.alt = 'Rzut 3D ' + savedRoom.name;
			img.onload = () => {
				loadedImages++;
				if (loadedImages === savedRoom.plan_3d.length) {
					carousel.classList.remove('loading');
				}
			};
			dummy.appendChild(img);
		});
	}
};

closeRoomInfo.addEventListener("click", () => {
	window.dispatchEvent(new Event('resize'));
	wrapper.classList.remove("show-room-info");
	wrapper.classList.remove("show-room-3d");
	wrapper.classList.remove("show-walk-3d");
	activateButton(buttonBuilding3d);
	showRoomInfo = false;
	showBuildingModel = true;
	showWalk3d = false;
});

hideRoomInfo.addEventListener("click", () => {
	window.dispatchEvent(new Event('resize'));
	wrapper.classList.toggle("show-room-info");
	showRoomInfo = !showRoomInfo;
});

buttonPlan3d.addEventListener("click", () => {
	if (!loaded) return;
	if (!savedRoom) return;
	showBuildingModel = false;
	showWalk3d = false;
	wrapper.classList.remove("show-walk-3d");
	fillRoomInfo();
	activateButton(buttonPlan3d);
});

buttonBuilding3d.addEventListener("click", () => {
	if (!loaded) return;
	wrapper.classList.remove("show-room-3d");
	wrapper.classList.remove("show-walk-3d");
	showBuildingModel = true;
	showWalk3d = false;
	activateButton(buttonBuilding3d);
});

buttonWalk3d.addEventListener("click", () => {
	if (!loaded) return;
	if (!savedRoom) return;
	wrapper.classList.remove("show-room-3d");
	wrapper.classList.add("show-walk-3d");
	showBuildingModel = false;
	showWalk3d = true;
	activateButton(buttonWalk3d);
	loadHriScene(savedRoom.walk_3d, 0);
});

const loadButton = document.getElementById("load_3d");
loadButton.addEventListener("click", () => {
	wrapper.classList.add("loading");
	load3dScene();
});

// Carousel handler

buttonPrevCarousel.addEventListener("click", () => {
	if (!loaded) return;
	if (!savedRoom) return;
	currentCarousel += 1;
	if (currentCarousel >= savedRoom.plan_3d.length) currentCarousel = 0;
	updateCarousel();
});

buttonNextCarousel.addEventListener("click", () => {
	if (!loaded) return;
	if (!savedRoom) return;
	currentCarousel -= 1;
	if (currentCarousel < 0) currentCarousel = savedRoom.plan_3d.length - 1;
	updateCarousel();
});

let oldx = 0;
let wasDragging = false;

carousel.addEventListener("dragstart", (e) => {
	e.preventDefault();
});

carousel.addEventListener("mousemove", (e) => {
	if (!loaded) return;
	if (!savedRoom) return;
	if (showBuildingModel) return;

	// Check if mouse is dragging
	if (e.buttons !== 1) {
		oldx = e.pageX;
		if (wasDragging) {
			wasDragging = false;
			carousel.classList.remove('dragging');
		}
		return;
	}
	if (!wasDragging) {
		wasDragging = true;
		carousel.classList.add('dragging');
	}

	if (e.pageX > oldx) {
		// Left
		currentCarousel -= 1;
		if (currentCarousel < 0) currentCarousel = savedRoom.plan_3d.length - 1;
	}
	else if (e.pageX < oldx) {
		// Right
		currentCarousel += 1;
		if (currentCarousel >= savedRoom.plan_3d.length) currentCarousel = 0;
	}
	oldx = e.pageX;
	updateCarousel();
});

const updateCarousel = () => {
	if (!loaded) return;
	if (!savedRoom) return;
	if (showBuildingModel) return;
	const image = document.getElementById('current-carousel-image');
	const images = dummy.getElementsByTagName('img');
	image.src = images[currentCarousel].src;
};

canvas.addEventListener('dragEvent', (e) => {
	if (!loaded) return;
	if (!showBuildingModel) return;
	if (e.detail.dragging) {
		canvas.style.cursor = 'grabbing';
	} else {
		canvas.style.cursor = 'grab';
	}
});
canvas.addEventListener('hoverEvent', (e) => {
	if (!loaded) return;
	if (!showBuildingModel) return;
	if (e.detail.hovering) {
		canvas.style.cursor = 'pointer';
		if (e.detail.roomId && ! dragging) {
			currentRoom = getRoomHoverInfo(e.detail.roomId);
			if (!currentRoom) {
				prompt.style.display = 'none';
				currentRoom = null;
				return;
			}
			prompt.innerHTML = currentRoom.name;
			prompt.style.display = 'inline';
		} else {
			currentRoom = null;
			prompt.style.display = 'none';
		}
	} else {
		currentRoom = null;
		if (! dragging) canvas.style.cursor = 'grab';
		prompt.style.display = 'none';
	}
});
canvas.addEventListener('mousemove', function(event) { 
	if (!showBuildingModel) return;
  const rect = canvas.getBoundingClientRect();
  let y = event.clientY - rect.top + 10;
  let x = event.clientX - rect.left + 10;
	// check if the prompt is going to be off the viewport
	if (x + prompt.clientWidth > sizes.width - 5) {
		x = sizes.width - prompt.clientWidth - 5;
	}
	if (y + prompt.clientHeight > sizes.height - 5) {
		y = sizes.height - prompt.clientHeight - 5;
	}
  prompt.style.left = x + 'px';
	prompt.style.top = y + 'px';
}); 
canvas.addEventListener('click', (e) => {
	if (!loaded) return;
	if (!currentRoom) return;
	if (!showBuildingModel) return;
	// TODO Do something on click
	showBuildingModel = false;
	fillRoomInfo();
});

function loadHriScene(views, view_index = 0) {
	showWalk3d = true;

	// Create points
	let points = [];
	if (views[view_index].points && views[view_index].points.length) {
		// copy points
		points = views[view_index].points.map((point) => {
			return {
				...point
			};
		});
	}
	walk3dPoints.innerHTML = '';
	points.forEach((point, index) => {
		const el = `
		<div class="point point-${index}">
			<img src="${point.icon}" alt="${point.name}" />
		</div>
		`;
		walk3dPoints.innerHTML += el;
	});

	if (debugMode) {
		gui.destroy();
		gui = new dat.GUI({ width: 400 });
		gui.addFolder(views[view_index].name)
	}
	points.forEach((point, index) => {
		points[index].element = document.getElementsByClassName(`point-${index}`)[0];

		if (debugMode) {
			gui.add(point.position, 'x', -100, 100, 0.01).name(point.name + ' x');
			gui.add(point.position, 'z', -100, 100, 0.01).name(point.name + ' z');
		}

		points[index].element.addEventListener('click', () => {
			const desiredName = point.name;
			for (const place of views) {
				if (place.name === desiredName) {
					loadHriScene(views, views.indexOf(place));
					break;
				}
			}
		});
	});

	// Create scene
	const sceneWalk = new THREE.Scene();
	sceneWalk.name = views[view_index].name;
	const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);
	const hriTexture = cubeTextureLoader.load([
		views[view_index].hri + 'px.' + views[view_index].hri_type,
		views[view_index].hri + 'nx.' + views[view_index].hri_type,
		views[view_index].hri + 'py.' + views[view_index].hri_type,
		views[view_index].hri + 'ny.' + views[view_index].hri_type,
		views[view_index].hri + 'pz.' + views[view_index].hri_type,
		views[view_index].hri + 'nz.' + views[view_index].hri_type
	]);
	hriTexture.encoding = THREE.LinearEncoding
	sceneWalk.background = hriTexture;

	const ambientLight = new THREE.AmbientLight(0xffffff, 1);
	ambientLight.name = 'ambientLight';
	ambientLight.position.set(0, 0, 0);
	sceneWalk.add(ambientLight);

	const cameraWalk = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
	cameraWalk.position.set(0, 0, 0);
	cameraWalk.rotation.set(0, 0, 0);
	cameraWalk.name = 'camera';
	sceneWalk.add(cameraWalk);

	const controlsWalk = new OrbitControls(cameraWalk, canvas);
	controlsWalk.enableDamping = false;
	controlsWalk.enablePan = false;
	controlsWalk.enableZoom = false;
	controlsWalk.enableRotate = true;
	controlsWalk.minDistance = 0;
	controlsWalk.maxDistance = 100;
	controlsWalk.minPolarAngle = 0;
	controlsWalk.maxPolarAngle = Math.PI;
	controlsWalk.minAzimuthAngle = -Infinity;
	controlsWalk.maxAzimuthAngle = Infinity;
	controlsWalk.target.set(1, 0, 0);
	controlsWalk.rotateSpeed *= -0.4;
	controlsWalk.update();

	cameraRotation = 180 - Math.floor((controlsWalk.getAzimuthalAngle() * 180 / Math.PI) % 360);

	// Handle minimap
	for (const walk3dCamera of walk3dCameras) {
		walk3dCamera.style.transform = `translate(-50%, -50%) rotate(${cameraRotation}deg)`;
		walk3dCamera.style.left = `${(views[view_index].position.x ?? -1) * 100}%`;
		walk3dCamera.style.top = `${(views[view_index].position.y ?? -1) * 100}%`;
	}
	controlsWalk.addEventListener('change', () => {
		cameraRotation = 180 - Math.floor((controlsWalk.getAzimuthalAngle() * 180 / Math.PI) % 360);
		for (const walk3dCamera of walk3dCameras) {
			walk3dCamera.style.transform = `translate(-50%, -50%) rotate(${cameraRotation}deg)`;
		}
	});

	const rendererWalk = new THREE.WebGLRenderer({
		canvas: canvas,
		antialias: true,
		alpha: true
	});

	window.addEventListener("resize", () => {
		// Update sizes
		sizes.width = window.innerWidth >= sizes.default_width ? sizes.default_width : window.innerWidth;
		sizes.height = window.innerHeight >= sizes.default_height ? sizes.default_height : window.innerHeight;
		canvas.style.width = sizes.width + "px";
		canvas.style.height = sizes.height + "px";

		// Update camera
		cameraWalk.aspect = sizes.width / sizes.height;
		cameraWalk.updateProjectionMatrix();

		// Update renderer
		rendererWalk.setSize(sizes.width, sizes.height);
		rendererWalk.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	});

	function tickWalk() {
		if (!showWalk3d) return;
		let oldWidth = sizes.default_width;
		sizes.default_width = canvasWrapper.clientWidth;
		sizes.default_height = canvasWrapper.clientHeight;
		if (oldWidth !== sizes.default_width) {
			window.dispatchEvent(new Event('resize'));
		}

		cameraWalk.updateMatrix();
		cameraWalk.updateMatrixWorld();
		const frustum = new THREE.Frustum();
		const matrix = new THREE.Matrix4().multiplyMatrices(cameraWalk.projectionMatrix, cameraWalk.matrixWorldInverse)
		frustum.setFromProjectionMatrix(matrix)


		// Points 3D
		for(const point of points)
    {
			const screenPosition = point.position.clone()
			if (!frustum.containsPoint(screenPosition)) {
				point.element.classList.add('hidden');
				continue;
			}
			point.element.classList.remove('hidden');
			screenPosition.project(cameraWalk)
			const translateX = screenPosition.x * sizes.width * 0.5
			const translateY = - screenPosition.y * sizes.height * 0.5
			point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    }

		controlsWalk.update();
		rendererWalk.render(sceneWalk, cameraWalk);
		window.requestAnimationFrame(tickWalk);
	}
	tickWalk();
}

function load3dScene() {
	// Scene
	const scene = new THREE.Scene();

	/**
	 * Textures
	 */

	const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);
	const map = '4';
	const mapType = 'png';
	const environmentMap = cubeTextureLoader.load([
		'/textures/environmentMaps/'+map+'/px.' + mapType,
		'/textures/environmentMaps/'+map+'/nx.' + mapType,
		'/textures/environmentMaps/'+map+'/py.' + mapType,
		'/textures/environmentMaps/'+map+'/ny.' + mapType,
		'/textures/environmentMaps/'+map+'/pz.' + mapType,
		'/textures/environmentMaps/'+map+'/nz.' + mapType
	]);
	environmentMap.encoding = THREE.sRGBEncoding
	scene.background = environmentMap;

	const debugObject = {}
	debugObject.envMapIntensity = 1
	const updateAllMaterials = () => {
		if (!showBuildingModel) return;
		scene.traverse((child) => {
			if (
				child instanceof THREE.Mesh &&
				(child.material instanceof THREE.MeshStandardMaterial ||
				child.material instanceof THREE.MeshPhysicalMaterial ||
				child.material instanceof THREE.MeshBasicMaterial)
			) {
				child.material.envMap = environmentMap;
				child.material.envMapIntensity = debugObject.envMapIntensity
				child.castShadow = true
				child.receiveShadow = true
				if (child.material) {
					if (child.material.name.includes('RoomMaterial')) {
						child.material.transparent = true;
						rooms.push(child);
					}
					if (child.material.name.includes('Glass')) {
						child.material.transparent = true
						child.material.opacity = 0.4
						child.material.reflectivity = 2.5
						child.material.roughness = 0.1
						if (child.material.envMapIntensity) child.material.envMapIntensity = 1
					}
				}
			}
		});
	};

	/**
	 * Objects
	 */

	// ROOMS
	let rooms = [];

	/**
	 * Models
	 */
	const gltfLoader = new GLTFLoader(loadingManager);

	let model = null;
	let sun = null;
	gltfLoader.load(
		config.model.path,
		(gltf) => {
			const name = config.model.name ?? 'Custom model';
			model = gltf.scene;
			model.name = name;
			model.rotation.x = config.model.rotation.x ?? 0;
			model.rotation.y = config.model.rotation.y ?? 0;
			model.rotation.z = config.model.rotation.z ?? 0;
			model.position.x = config.model.position.x ?? 0;
			model.position.y = config.model.position.y ?? 0;
			model.position.z = config.model.position.z ?? 0;
			model.castShadow = true;
			model.receiveShadow = true;
			for (const child of model.children) {
				if (child.name === 'Sun001') {
					sun = child;
				}
				if (child.material && child.material.texture) {
					child.material.texture.minFilter = THREE.NearestFilter;
					child.material.texture.magFilter = THREE.NearestFilter;
				}
				for (const child2 of child.children) {
					if (child2.material && child2.material.texture) {
						child2.material.texture.minFilter = THREE.NearestFilter;
						child2.material.texture.magFilter = THREE.NearestFilter;
					}
				}
			}
			if (sun) {
				sun.intensity = 5.5;
				sun.castShadow = true
				sun.shadow.normalBias = 0.05
				sun.shadow.mapSize.width = 2048
				sun.shadow.mapSize.height = 2048
				sun.shadow.camera.near = 0.5
				sun.shadow.camera.far = 500
				sun.shadow.camera.left = -100
				sun.shadow.camera.right = 100
				sun.shadow.camera.top = 100
				sun.shadow.camera.bottom = -100
			}
			
			scene.add(model);
			updateAllMaterials();
		}
	);

	/**
	 * Lights
	 */
	const ambientLight = new THREE.AmbientLight(0xFDDFBB, 0.5);
	scene.add(ambientLight);

	/**
	 * Raycaster
	 */
	const raycaster = new THREE.Raycaster();
	let currentIntersect = null;

	window.addEventListener("resize", () => {
		// Update sizes
		sizes.width = window.innerWidth >= sizes.default_width ? sizes.default_width : window.innerWidth;
		sizes.height = window.innerHeight >= sizes.default_height ? sizes.default_height : window.innerHeight;
		canvas.style.width = sizes.width + "px";
		canvas.style.height = sizes.height + "px";

		// Update camera
		camera.aspect = sizes.width / sizes.height;
		camera.updateProjectionMatrix();

		// Update renderer
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	});

	/**
	 * Mouse
	 */
	const mouse = new THREE.Vector2();
	window.addEventListener("mousemove", (event) => {
		const rect = canvas.getBoundingClientRect();
		mouse.x = (event.clientX - rect.left) / sizes.width * 2 - 1;
		mouse.y = -((event.clientY - rect.top) / sizes.height) * 2 + 1;
	});

	/**
	 * Camera
	 */
	// Base camera
	const camera = new THREE.PerspectiveCamera(
		75,
		sizes.width / sizes.height,
		0.1,
		250
	);
	camera.position.x = 0;
	camera.position.y = 55;
	camera.position.z = -35;
	scene.add(camera);

	// display axis
	const axesHelper = new THREE.AxesHelper( 50 );
	// scene.add( axesHelper );

	// Controls
	const controls = new OrbitControls(camera, canvas);
	controls.enableDamping = false;
	controls.enablePan = false;
	// prevent camera from going through ground and skybox
	controls.maxPolarAngle = 1.143;
	controls.minPolarAngle = 1.143;
	controls.maxDistance = 29.55;
	controls.minDistance = 29.55;

	controls.addEventListener('change', () => {
		if (dragging) return;
		if (!showBuildingModel) return;
		dragging = true
		canvas.dispatchEvent(new CustomEvent("dragEvent", { detail: {dragging: true} }));
	});
	controls.addEventListener('end', () => {
		if (!showBuildingModel) return;
		setTimeout(() => {
			dragging = false
			canvas.dispatchEvent(new CustomEvent("dragEvent", { detail: {dragging: false} }));
		}, 50);
	});

	/**
	 * Renderer
	 */
	const renderer = new THREE.WebGLRenderer({
		canvas: canvas,
		antialias: true,
	});
	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	renderer.physicallyCorrectLights = true
	renderer.outputEncoding = THREE.sRGBEncoding
	renderer.shadowMap.enabled = true
	renderer.shadowMap.type = THREE.PCFSoftShadowMap
	renderer.toneMapping = THREE.ReinhardToneMapping
	renderer.toneMappingExposure = 1.5

	/**
	 * Animate
	 */
	const clock = new THREE.Clock();

	const tick = () => {
		stats.begin();
		if (showBuildingModel) {
			let oldWidth = sizes.default_width;
			sizes.default_width = canvasWrapper.clientWidth;
			sizes.default_height = canvasWrapper.clientHeight;
			if (oldWidth !== sizes.default_width) {
				window.dispatchEvent(new Event('resize'));
			}
			
			const elapsedTime = clock.getElapsedTime();

			// Mouse raycasting
			raycaster.setFromCamera(mouse, camera);
			for (const object of rooms) {
				object.material.opacity = 0.3;
			}
			const intersects = raycaster.intersectObjects(rooms);
			if (intersects.length && ! dragging) {
				const group = intersects[0].object;//?.parent;
				const roomId = group.name.replace(/ *\([^)]*\) */g, "");
				
				for (const object of rooms) {
					if (object.name.replace(/ *\([^)]*\) */g, "") === roomId) {
						object.material.opacity = 0.5;
					}
				}
				if (currentIntersect === null || currentIntersect !== group) {
					canvas.dispatchEvent(new CustomEvent("hoverEvent", { detail: {
						hovering: true,
						roomId
					} }));
				}
				currentIntersect = group;
			} else {
				if (currentIntersect) {
					canvas.dispatchEvent(new CustomEvent("hoverEvent", { detail: {hovering: false} }));
				}
				currentIntersect = null;
			}

			// Update controls
			controls.update();

			// Render
			renderer.render(scene, camera);

			// Call tick again on the next frame
			stats.end();
		}
	};


	// (function requestBrowserFrame() {
	// 	tick()
	// 	window.requestAnimationFrame(requestBrowserFrame);
	// })()

	(() => {
		renderer.xr.enabled = true;
		document.body.appendChild( VRButton.createButton( renderer ) );
		(function requestVrFrame() {
			tick();
			renderer.setAnimationLoop( requestVrFrame );
		})()
	})()
};