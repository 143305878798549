export const model = {
	path: "/models/base/building_terrain.glb",
	name: "Test Scenery",
	rotation: {
		// y: Math.PI * 0.5,
	},
	position: {
		x: 0,
		y: -10,
		z: 0,
	},
};